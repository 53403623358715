import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Loader from "./Loader";
import { RiTruckFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import { DownloadTableExcel, useDownloadExcel } from "react-export-table-to-excel";
import { IoNewspaperOutline } from "react-icons/io5";

const Completed = () => {
  const [page, setPage] = useState(1);
  const nagivate = useNavigate();
  // const [sheetData, setSheetData] = useState([])
  const [from, setFrom] = useState(1680287400000);
  const [to, setTo] = useState(4102338600000);
  const [loaders, setLoader] = useState(true);
  const [completedData, setCompletedData] = useState([]);
  const [SearchQuery, setSearchQuery] = useState({
    searchInput: "",
    searchSelect: "",
  });
  const [count, setCount] = useState(0);
  const [multiSelectInput, setMultiSelectInput] = useState({
    remove: "false",
    select: "false",
  });
  const [originSelect, setOriginSelect] = useState([]);
  const [originFilter, setOriginFilter] = useState([
    {
      cat: "Group 1",
      key: "SHPL-GURGAON HARIYANA-SEIMENS HEALTHCARE PVT. LTD.",
    },
    {
      cat: "Group 2",
      key: "SHPL- KOLKATA AIRPORT- SIEMENS HEALTHCARE PVT.LTD.",
    },
    {
      cat: "Group 3",
      key: "SHPL- CHENNAI SEAPORT- SIEMENS HEALTHCARE PVT.LTD.",
    },
    {
      cat: "Group 4",
      key: "SHPL- DELHI AIRPORT- SIEMENS HEALTHCARE PVT.LTD.",
    },
    {
      cat: "Group 5",
      key: "SHPL - APML BHIWANDI- SIEMENS HEALTHCARE PVT LTD",
    },
    {
      cat: "Group 6",
      key: "SHPL- BANGALORE AIRPORT- SIEMENS HEALTHCARE PVT.LTD.",
    },
    {
      cat: "Group 7",
      key: "SHPL-APML CHENNAI- SIEMENS HEALTHCARE PVT.LTD.",
    },
    {
      cat: "Group 8",
      key: "SHPL-KOLKATA WAREHOUSE- SIEMENS HEALTHCARE PVT.LTD.",
    },
    {
      cat: "Group 9",
      key: "SHPL-BANGALORE WAREHOUSE- SIEMENS HEALTHCARE PVT.LTD.",
    },
    {
      cat: "Group 10",
      key: "SHPL-CHENNAI WAREHOUSE- SIEMENS HEALTHCARE PVT.LTD.",
    },
    {
      cat: "Group 11",
      key: "SHPL-DELHI WAREHOUSE- SIEMENS HEALTHCARE PVT.LTD.",
    },
    {
      cat: "Group 12",
      key: "SHPL - NHAVA SHEVA- SIEMENS HEALTHCARE PVT LTD",
    },
    {
      cat: "Group 13",
      key: "SHPL- KOLKATA SEAPORT- SIEMENS HEALTHCARE PVT.LTD.",
    },
    {
      cat: "Group 14",
      key: "SHPL- CHENNAI AIRPORT- SIEMENS HEALTHCARE PVT.LTD",
    },
    {
      cat: "Group 15",
      key: "SHPL - BGR WAREHOUSE- SIEMENS HEALTHCARE PVT.LTD",
    },
    {
      cat: "Group 16",
      key: "SHPL-APML BANGALORE- SIEMENS HEALTHCARE PVT.LTD.",
    },
    {
      cat: "Group 17",
      key: "SHPL - APML CHOWK - SIEMENS HEALTHCARE PVT LTD",
    },
    {
      cat: "Group 18",
      key: "SHPL - MUMBAI AIRPORT - SIEMENS HEALTHCARE PVT LTD",
    },
    {
      cat: "Group 19",
      key: "SHPL - PRESS METAL COMPANY - SIEMENS HEALTHCARE PVT. LTD.",
    },
    {
      cat: "Group 20",
      key: "SHPL - RAJLAXMI LOGISTICS PARK- SIEMENS HEALTHCARE PVT LTD",
    },
    {
      cat: "Group 21",
      key: "SHPL-Banglore-Factory-SIEMENS HEALTHCARE PVT.LTD.",
    },
  ]);

  const date = new Date();

  const [startDate, setStartDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1).getTime()
  );
  const [endDate, setEndDate] = useState(
    new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime()
  );
  const [isMonthlyFilter, setIsMonthlyFIlter] = useState(false);
  const tableRef = useRef(null);

  const gettingData = async () => {
    setLoader(true);
    var enrouteData = await axios.get(
      `https://apml-api-b1.glitch.me/api/v1/get/pageData?startDate=${startDate}&endDate=${endDate}`
    );
    setLoader(false);
    setCompletedData(enrouteData.data.data);
    console.log(completedData);
  };

  const handleMonthlyFilter = (month) => {
    setIsMonthlyFIlter((prev) => prev + 1);

    const months = getPastMonthsFilter();

    months.forEach((m) => {
      if (m[0] === month) {
        const date = new Date(m[1]);  

        const startDate = new Date(date.getFullYear(), date.getMonth(), 1).getTime();
        const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime();

        setStartDate(startDate);
        setEndDate(endDate);
      }
    });
  };

  function subtractDates1(date1, date2) {
    const difference = date1 - date2;
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const re = `${days}D ${hours}H`;
    return re.toString();
  }

  const convertmilisecond = (mydate) => {
    const milliseconds = mydate;
    const date = new Date(milliseconds);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const formattedDateTime = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")} ${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    return formattedDateTime;
  };

  // function for stages Early, Delay, OnTime

  function early_delay(a, b, c, d) {
    if (a === "" || a === null) {
      return "enter the km";
    }
    if (b === "20FT AIR SUSPENSION" || b === "40 FT AIR SUSPENSION") {
      var number_days = Number(a) / 200;
      const days = Math.floor(number_days) + 2;
      const DaysInMilliseconds = days * 24 * 60 * 60 * 1000;
      const timestamp = c + DaysInMilliseconds;
      var expecteddate = d;
      const timestamp1 = timestamp;
      const timestamp2 = expecteddate;
      const timeDiff = Math.abs(timestamp1 - timestamp2); // Calculate the absolute difference in milliseconds
      const oneDayInMilliseconds = 86400000;
      if (timeDiff >= oneDayInMilliseconds) {
        return "Early"; // Difference is greater than 1 day
      } else if (timeDiff >= 0.9 * oneDayInMilliseconds) {
        return "On time"; // Difference is approximately equal to 1 day
      } else {
        return "Delayed"; // Difference is less than 1 day
      }
    } else {
      var number_days = Number(a) / 300;
      const days = Math.floor(number_days) + 2;
      const DaysInMilliseconds = days * 24 * 60 * 60 * 1000;
      const timestamp = c + DaysInMilliseconds;
      var expecteddate = d;
      const timestamp1 = timestamp;
      const timestamp2 = expecteddate;
      const timeDiff = Math.abs(timestamp1 - timestamp2); // Calculate the absolute difference in milliseconds
      const oneDayInMilliseconds = 86400000;
      if (timeDiff >= oneDayInMilliseconds) {
        return "Early"; // Difference is greater than 1 day
      } else if (timeDiff >= 0.9 * oneDayInMilliseconds) {
        return "On time"; // Difference is approximately equal to 1 day
      } else {
        return "Delayed"; // Difference is less than 1 day
      }
      return timestamp;
    }
  }

  useEffect(() => {
    const token = sessionStorage.getItem("id");
    if (token) {
      gettingData();
      console.log("authorised");
    } else {
      nagivate("/login");
    }
  }, [multiSelectInput, page, isMonthlyFilter, nagivate]);

  console.log();

  let temp;

  const getCount = () => {
    let tr = document.getElementsByTagName("tr");
    temp = tr.length;

    setTimeout(() => {
      setCount(temp - 1);
    }, 1000);
  };

  getCount();

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef,
    filename: "Completed Orders",
    sheet: "Completed Orders",
  });

  return (
    <>
      <main id="completedTable">
        <div className="main_table-export">
          <div className="export">
            {/* <button onClick={exportExcelFile}> */}
            <DownloadTableExcel
              filename="Completed Orders"
              sheet="Completed Orders"
              currentTableRef={tableRef.current}
            >
              <button onClick={onDownload}>
                <h1> EXPORT</h1>
              </button>
            </DownloadTableExcel>
          </div>
        </div>
        <div className="main_table-heading">
          <div className="heading">
            <RiTruckFill
              className="heading-icon"
              style={{ color: "orange" }}
            />
            <h1>
              {/* {completedData.length} */}
              COMPLETED
              {/* <span>{`${completedData?.data?.orderCount != undefined ? completedData?.data?.orderCount : 0}`}</span> */}
              <span>{completedData.length}</span>
            </h1>
          </div>
        </div>

        <div>
          <label
            htmlFor="from"
            style={{ color: "black" }}
          >
            FROM
          </label>
          <input
            type="date"
            name="from"
            onChange={(e) => {
              const mill = new Date(e.target.value).getTime();
              setFrom(mill);
            }}
          />
          <label
            htmlFor="from"
            style={{ color: "black" }}
          >
            TO
          </label>
          <input
            type="date"
            name="to"
            onChange={(e) => {
              const mill = new Date(e.target.value).getTime();
              setTo(mill);
            }}
          />
        </div>
        <br />

        <div className="completed-pagination">
          <input
            type="text"
            style={{ fontSize: "16px" }}
            placeholder="Search here"
            value={SearchQuery.searchInput}
            onChange={(e) =>
              setSearchQuery((prevState) => ({ ...prevState, searchInput: e.target.value }))
            }
            className="table-search-input"
          />
          <div className="origin-checkbox">
            <Multiselect
              displayValue="key"
              onKeyPressFn={(e) => {}}
              onRemove={(e) => {
                setOriginSelect(e);
                setMultiSelectInput((prevState) => ({ ...prevState, remove: true, select: false }));
              }}
              onSearch={(e) => {}}
              onSelect={(e) => {
                setOriginSelect(e);
                setMultiSelectInput((prevState) => ({ ...prevState, select: true, remove: false }));
              }}
              color={"black"}
              fontSize={"18px"}
              textTransform={"uppercase"}
              border={"2px solid black"}
              options={originFilter}
              placeholder="Origin Filter"
              selectedValueDecorator={(e) => {}}
            />
          </div>
          <div style={{ position: "relative" }}>
            <p
              style={{
                position: "absolute",
                transform: "translateX(215px) translateY(17px)",
                fontSize: "15px",
              }}
            >
              Monthly Filter
            </p>
            <select
              name="monthfilter"
              id="monthfilter"
              className="com-month-filter"
              placeholder="Monthly Filter"
              onChange={(e) => handleMonthlyFilter(e.target.value)}
            >
              <PastMonthsOptions />
            </select>
          </div>
        </div>
        {
          <>
            {loaders ? (
              <Loader />
            ) : (
              <table
                className="main-table"
                id="excel_table"
                ref={tableRef}
              >
                <thead>
                  <tr>
                    <th className="table-th">Order Number </th>
                    <th className="table-th">origin</th>
                    <th className="table-th">GC Number </th>
                    <th className="table-th">Stages</th>
                    <th className="table-th">Order by EDD </th>
                    <th className="table-th">Order Days </th>

                    <th className="table-th">Vehicle No. </th>
                    <th className="table-th">Vehicle type </th>
                    {/* <th className="table-th">shipment created </th> */}
                    <th className="table-th">Material Name </th>
                    <th className="table-th">Ship To Party</th>
                    <th className="table-th">Ship TO Address</th>
                    {/* <th className="table-th">contract EDD </th> */}
                    <th className="table-th">Escalations</th>
                    <th className="table-th">Tracking Update </th>
                    <th className="table-th">abnormalities</th>
                    <th className="table-th">Actual Arrival </th>
                    <th className="table-th">Invoice Number </th>
                    <th className="table-th">Invoice Date </th>
                    <th
                      className="table-th hovertext5"
                      data-hover={"Arrival Time vs Get In Time"}
                      style={{ fontSize: "1.1rem" }}
                    >
                      at vs gt{" "}
                    </th>
                    <th
                      className="table-th hovertext5"
                      data-hover={"Get In Time vs Depature Time"}
                      style={{ fontSize: "1.1rem" }}
                    >
                      gt vs dt{" "}
                    </th>
                    <th
                      className="table-th hovertext5"
                      data-hover={"Get In Time vs Loading Start"}
                      style={{ fontSize: "1.1rem" }}
                    >
                      gt vs ls{" "}
                    </th>
                    <th
                      className="table-th hovertext5"
                      data-hover={"Loading Start vs Loading End"}
                      style={{ fontSize: "1.1rem" }}
                    >
                      ls vs le{" "}
                    </th>
                    <th
                      className="table-th hovertext5"
                      data-hover={"Loading End vs Depature Time"}
                      style={{ fontSize: "1.1rem" }}
                    >
                      le vs dt{" "}
                    </th>
                    <th
                      className="table-th hovertext5"
                      data-hover={"arrival Time vs Depature Time"}
                      style={{ fontSize: "1.1rem" }}
                    >
                      at vs dt{" "}
                    </th>
                    <th className="table-th">Transit time </th>
                    <th className="table-th">unloading at vs unls</th>
                    <th className="table-th">unloading uns vs unle</th>
                    <th className="table-th">unloading unlle vs dt</th>
                    <th className="table-th">unloading at vs dt</th>
                    <th className="table-th">Total unloading Time</th>
                    <th className="table-th">Pod</th>
                    <th className="table-th">Pod since </th>
                    <th className="table-th">Pod Pic</th>
                    <th
                      className="table-th"
                      style={{ lineHeight: "1rem" }}
                    >
                      DOCUMENTS <span style={{ display: "block" }}>IN || EWAY || PAC.LIST</span>
                    </th>
                    <th className="table-th">Pending Remark </th>
                    <th className="table-th">Enroute Remark </th>
                    <th className="table-th">Atpickup Remark </th>
                    <th className="table-th">Intransit Remark </th>
                    <th className="table-th">AtUNloading Remark </th>
                  </tr>
                </thead>

                <tbody>
                  {completedData.length > 0 ? (
                    completedData
                      .filter((res, index) => {
                        const searchField = JSON.stringify(res);
                        return originSelect.length !== 0
                          ? res?.Order?.origin
                              ?.toLowerCase()
                              .includes(originSelect[0]?.key?.toLowerCase()) ||
                              res?.Order?.origin
                                ?.toLowerCase()
                                .includes(originSelect[1]?.key?.toLowerCase()) ||
                              res?.Order?.origin
                                ?.toLowerCase()
                                .includes(originSelect[2]?.key?.toLowerCase()) ||
                              res?.Order?.origin
                                ?.toLowerCase()
                                .includes(originSelect[3]?.key?.toLowerCase()) ||
                              res?.Order?.origin
                                ?.toLowerCase()
                                .includes(originSelect[4]?.key?.toLowerCase()) ||
                              res?.Order?.origin
                                ?.toLowerCase()
                                .includes(originSelect[5]?.key?.toLowerCase()) ||
                              res?.Order?.origin
                                ?.toLowerCase()
                                .includes(originSelect[6]?.key?.toLowerCase()) ||
                              res?.Order?.origin
                                ?.toLowerCase()
                                .includes(originSelect[7]?.key?.toLowerCase()) ||
                              res?.Order?.origin
                                ?.toLowerCase()
                                .includes(originSelect[8]?.key?.toLowerCase()) ||
                              res?.Order?.origin
                                ?.toLowerCase()
                                .includes(originSelect[9]?.key?.toLowerCase()) ||
                              res?.Order?.origin
                                ?.toLowerCase()
                                .includes(originSelect[10]?.key?.toLowerCase()) ||
                              res?.Order?.origin
                                ?.toLowerCase()
                                .includes(originSelect[11]?.key?.toLowerCase()) ||
                              res?.Order?.origin
                                ?.toLowerCase()
                                .includes(originSelect[12]?.key?.toLowerCase()) ||
                              res?.Order?.origin
                                ?.toLowerCase()
                                .includes(originSelect[13]?.key?.toLowerCase()) ||
                              res?.Order?.origin
                                ?.toLowerCase()
                                .includes(originSelect[14]?.key?.toLowerCase()) ||
                              res?.Order?.origin
                                ?.toLowerCase()
                                .includes(originSelect[15]?.key?.toLowerCase()) ||
                              res?.Order?.origin
                                ?.toLowerCase()
                                .includes(originSelect[16]?.key?.toLowerCase()) ||
                              res?.Order?.origin
                                ?.toLowerCase()
                                .includes(originSelect[17]?.key?.toLowerCase()) ||
                              res?.Order?.origin
                                ?.toLowerCase()
                                .includes(originSelect[18]?.key?.toLowerCase()) ||
                              res?.Order?.origin
                                ?.toLowerCase()
                                .includes(originSelect[19]?.key?.toLowerCase()) ||
                              res?.Order?.origin
                                ?.toLowerCase()
                                .includes(originSelect[20]?.key?.toLowerCase()) ||
                              res?.Order?.origin
                                ?.toLowerCase()
                                .includes(originSelect[21]?.key?.toLowerCase())
                          : SearchQuery.searchInput.length === 0 && originSelect.length === 0
                          ? res?.Order?.origin
                              ?.toLowerCase()
                              .includes(SearchQuery?.searchSelect?.toLowerCase())
                          : searchField
                              .toLocaleLowerCase()
                              .includes(SearchQuery?.searchInput?.toLocaleLowerCase());
                      })
                      .map((res) => {
                        // if (
                        //   convertmilisecond(res.Order.pickupdate) > convertmilisecond(from)
                        //   &&
                        //   convertmilisecond(res.Order.pickupdate) < convertmilisecond(to)
                        // )
                        //  {
                        return (
                          <tr>
                            <td className="td-main">{res?.Order?.orderNumber}</td>
                            <td className="td-main">{res?.Order?.origin.split("-")[1]}</td>
                            <td className="td-main">
                              {res?.GC_DATA
                                ? res?.GC_DATA?.DocketNo || res?.GC_DATA
                                : "no Gc Updated"}
                            </td>
                            <td
                              className={
                                res?.status ||
                                early_delay(
                                  res?.EwayBill?.actualDist || res?.TotalDistance,
                                  res?.Order?.vehicletype,
                                  res?.Departed,
                                  res?.UnDeparted
                                ) === "Early"
                                  ? "early"
                                  : res?.status ||
                                    early_delay(
                                      res?.EwayBill?.actualDist || res?.TotalDistance,
                                      res?.Order?.vehicletype,
                                      res?.Departed,
                                      res?.UnDeparted
                                    ) === "Delayed"
                                  ? "delay"
                                  : res?.status ||
                                    early_delay(
                                      res?.EwayBill?.actualDist || res?.TotalDistance,
                                      res?.Order?.vehicletype,
                                      res?.Departed,
                                      res?.UnDeparted
                                    ) === "On time"
                                  ? "ontime"
                                  : ""
                              }
                            >
                              {res?.status
                                ? res?.status
                                : // ""
                                  early_delay(
                                    res?.EwayBill?.actualDist || res?.TotalDistance,
                                    res?.Order?.vehicletype,
                                    res?.Departed,
                                    res?.UnDeparted
                                  )}
                            </td>
                            <td className="td-main">{res?.Order?.orderby}</td>
                            <td className="td-main">
                              {res?.Order?.pickupdate
                                ? convertmilisecond(res?.Order?.pickupdate)
                                : "--"}
                            </td>
                            <td
                              className="td-main"
                              style={{ textTransform: "uppercase" }}
                            >
                              {res?.vehicleAssign?.VehicleNumber}
                            </td>
                            <td className="td-main">{res?.Order?.vehicletype}</td>
                            <td className="td-main">{res?.Order?.material}</td>
                            <td className="td-main">{res?.Order?.shiptoparty}</td>
                            <td className="td-main">{res?.Order?.shiptoaddress}</td>
                            <td className="td-main">--</td>
                            <td className="td-main">--</td>
                            <td className="td-main">--</td>
                            <td className="td-main">{new Date(res.ArrivalTime).toDateString()}</td>
                            <td className="td-main">{res.InvoiceNo || "-"}</td>
                            <td className="td-main">{res.InvoiceDate || "-"}</td>
                            <td
                              className="td-main"
                              style={{
                                fontWeight: "bold",
                                color:
                                  subtractDates1(res?.GateIn, res?.ArrivalTime) < "0D 1H"
                                    ? "#00ff00"
                                    : "red",
                              }}
                            >
                              {subtractDates1(res?.GateIn, res?.ArrivalTime)}
                            </td>

                            <td
                              className="td-main"
                              style={{
                                fontWeight: "bold",
                                color:
                                  subtractDates1(res?.Departed, res?.GateIn) < "0D 1H"
                                    ? "#00ff00"
                                    : "red",
                              }}
                            >
                              {subtractDates1(res?.Departed, res?.GateIn)}
                            </td>

                            <td
                              className="td-main"
                              style={{
                                fontWeight: "bold",
                                color:
                                  subtractDates1(res?.LoadingStart, res?.GateIn) < "0D 1H"
                                    ? "#00ff00"
                                    : "red",
                              }}
                            >
                              {subtractDates1(res?.LoadingStart, res?.GateIn)}
                            </td>

                            <td
                              className="td-main"
                              style={{
                                fontWeight: "bold",
                                color:
                                  subtractDates1(res?.LoadingEnd, res?.LoadingStart) < "0D 1H"
                                    ? "#00ff00"
                                    : "red",
                              }}
                            >
                              {subtractDates1(res?.LoadingEnd, res?.LoadingStart)}
                            </td>

                            <td
                              className="td-main"
                              style={{
                                fontWeight: "bold",
                                color:
                                  subtractDates1(res?.Departed, res?.LoadingEnd) < "0D 1H"
                                    ? "#00ff00"
                                    : "red",
                              }}
                            >
                              {subtractDates1(res?.Departed, res?.LoadingEnd)}
                            </td>

                            <td
                              className="td-main"
                              style={{
                                fontWeight: "bold",
                                color:
                                  subtractDates1(res?.Departed, res?.ArrivalTime) < "0D 1H"
                                    ? "#00ff00"
                                    : "red",
                              }}
                            >
                              {subtractDates1(res?.Departed, res?.ArrivalTime)}
                            </td>

                            <td
                              className="td-main"
                              style={{
                                fontWeight: "bold",
                                color:
                                  subtractDates1(res?.ArrivalTime, res?.Departed) < "0D 1H"
                                    ? "#00ff00"
                                    : "red",
                              }}
                            >
                              {subtractDates1(res?.ArrivalTime, res?.Departed)}
                            </td>

                            <td
                              className="td-main"
                              style={{
                                fontWeight: "bold",
                                color:
                                  subtractDates1(res?.LoadingStart, res?.ArrivalTime) < "0D 1H"
                                    ? "#00ff00"
                                    : "red",
                              }}
                            >
                              {subtractDates1(res?.LoadingStart, res?.ArrivalTime)}
                            </td>

                            <td
                              className="td-main"
                              style={{
                                fontWeight: "bold",
                                color:
                                  subtractDates1(res?.LoadingEnd, res?.LoadingStart) < "0D 1H"
                                    ? "#00ff00"
                                    : "red",
                              }}
                            >
                              {subtractDates1(res?.LoadingEnd, res?.LoadingStart)}
                            </td>

                            <td
                              className="td-main"
                              style={{
                                fontWeight: "bold",
                                color:
                                  subtractDates1(res?.Departed, res?.LoadingEnd) < "0D 1H"
                                    ? "#00ff00"
                                    : "red",
                              }}
                            >
                              {subtractDates1(res?.Departed, res?.LoadingEnd)}
                            </td>

                            <td
                              className="td-main"
                              style={{
                                fontWeight: "bold",
                                color:
                                  subtractDates1(res?.Departed, res?.ArrivalTime) < "0D 1H"
                                    ? "#00ff00"
                                    : "red",
                              }}
                            >
                              {subtractDates1(res?.Departed, res?.ArrivalTime)}
                            </td>

                            <td
                              className="td-main"
                              style={{
                                fontWeight: "bold",
                                color:
                                  subtractDates1(res?.Departed, res?.ArrivalTime) < "0D 1H"
                                    ? "#00ff00"
                                    : "red",
                              }}
                            >
                              {subtractDates1(res?.Departed, res?.ArrivalTime)}
                            </td>
                            <td className="td-main">{res?.podimage ? "submitted" : "empty"}</td>
                            <td className="td-main">{"--"}</td>

                            <td className="td-main">
                              <Link to={res?.podimage}>{res?.podimage ? "Pod_image" : "none"}</Link>
                            </td>
                            <td className="td-main">{"--"}</td>
                            <td
                              className="td-main"
                              style={{ color: res.Order.Pending_Remark ? "blue" : "black" }}
                            >
                              {res.Order?.Pending_Remark ? res.Order?.Pending_Remark : "no Remark"}
                            </td>
                            <td
                              className="td-main"
                              style={{
                                color: res.Order.Enroute_Remark ? "blue" : "black",
                                fontWeight: res.Order.Enroute_Remark ? "bold" : "",
                                textTransform: res.Order.Enroute_Remark ? "uppercase" : "",
                              }}
                            >
                              {res.Order?.Enroute_Remark ? res.Order?.Enroute_Remark : "no Remark"}
                            </td>
                            <td
                              className="td-main"
                              style={{
                                color: res.Order.Atpickup_Remark ? "blue" : "black",
                                fontWeight: res.Order.Atpickup_Remark ? "bold" : "",
                                textTransform: res.Order.Atpickup_Remark ? "uppercase" : "",
                              }}
                            >
                              {res.Order?.Atpickup_Remark
                                ? res.Order?.Atpickup_Remark
                                : "no Remark"}
                            </td>
                            <td
                              className="td-main"
                              style={{
                                color: res.Order.Intransit_Remark ? "blue" : "black",
                                fontWeight: res.Order.Intransit_Remark ? "bold" : "",
                                textTransform: res.Order.Intransit_Remark ? "uppercase" : "",
                              }}
                            >
                              {res.Order?.Intransit_Remark
                                ? res.Order?.Intransit_Remark
                                : "no Remark"}
                            </td>
                            <td
                              className="td-main"
                              style={{
                                color: res.Order.Atunloading_Remark ? "blue" : "black",
                                fontWeight: res.Order.Atunloading_Remark ? "bold" : "",
                                textTransform: res.Order.Atunloading_Remark ? "uppercase" : "",
                              }}
                            >
                              {res.Order?.Atunloading_Remark
                                ? res.Order?.Atunloading_Remark
                                : "no remark"}
                            </td>
                          </tr>
                        );
                        // }
                      })
                  ) : (
                    <tr className="no-data">
                      <div>
                        <IoNewspaperOutline />
                      </div>
                      <p>We Dont Have Any Completed Orders Yet</p>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </>
        }
      </main>
    </>
  );
};

function PastMonthsOptions() {
  const getPastTwoMonths = () => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const currentMonthIndex = new Date().getMonth();
    const currentYear = new Date().getFullYear();

    const options = [];

    for (let i = 0; i < 3; i++) {
      const monthIndex = (currentMonthIndex - i + 12) % 12;
      const month = months[monthIndex];
      const year = monthIndex > currentMonthIndex ? currentYear - 1 : currentYear;
      const optionValue = `${month} - ${year}`;
      options.push([month, optionValue]);
    }

    return options;
  };

  const months = getPastTwoMonths();

  return (
    <>
      {months.map((month) => (
        <option
          key={month[0]}
          value={month[0]}
        >
          {month[1]}
        </option>
      ))}
    </>
  );
}

function getPastMonthsFilter() {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dates = [];
  const date = new Date();

  for (let i = 0; i < 3; i++) {
    const month = new Date(date.getFullYear(), date.getMonth() - i, 1);
    dates.push([
      months[month.getMonth()],
      new Date(date.getFullYear(), date.getMonth() - i, 1).toJSON(),
    ]);
  }

  return dates;
}

export default Completed;
