import React from 'react'

const TentativeCalculator = () => {
  return (
    <main>
      <div class="iframe">
        <iframe style={{ marginBottom: "0px" }} src="https://shpl-cal.netlify.app/"></iframe>
      </div>
      <div class="second">

      </div>
      <div class="third">

      </div>
    </main>
  )
}

export default TentativeCalculator